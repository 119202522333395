<template>
  <div class="page">
    <a-tabs
     
      v-model="currentKey"
      style="height: 100%"
      @change="onChange"
    >
      <!-- <a-tab-pane key="1" tab="运费模板列表">
        <a-form-model layout="inline" :model="formInline">
          <a-row justify="space-between">
            <a-col span="20">
              <a-form-model-item label="店铺默认运费模板">
                <a-select style="width: 220px">
                  <a-select-option value="jack"> Jack </a-select-option>
                  <a-select-option value="lucy"> Lucy </a-select-option>
                  <a-select-option value="disabled" disabled>
                    Disabled
                  </a-select-option>
                  <a-select-option value="Yiminghe"> yiminghe </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="运费">
                <div class="flex">
                  <a-input class="w220" v-model="formInline.password">
                  </a-input>
                  <span style="margin-left: 4px">元</span>
                </div>
              </a-form-model-item>
            </a-col>
            <a-col span="4" style="text-align: right">
              <a-form-model-item>
                <a-button type="primary">保存配置</a-button>
              </a-form-model-item></a-col
            >
          </a-row>
        </a-form-model>
      </a-tab-pane> -->
      <a-tab-pane key="1" tab="重量运费模板列表" force-render>
        <tem-list></tem-list>
      </a-tab-pane>
      <a-tab-pane key="2" :tab="isEdit ? '编辑重量运费模板':'添加重量运费模板'">
        <add-weight-tem ref="weightRef"></add-weight-tem>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: "freightTemplate",
  components: {
    addWeightTem: () => import("./AddWeightTem"),
    temList: () => import("./list"),
  },
  data() {
    return {
      currentKey: "1",
      formInline: {
        user: "",
        password: "",
      },
    };
  },
  computed: {
    isEdit () {
      return this.$route.query.isEdit
    }
  },
  watch: {
    $route: {
      handler: function () {
      console.log(this.$route, 'route');
      if (this.$route.query.isEdit) {
        this.currentKey = "2";
      }
    },
    immediate: true
    }
  },
  methods: {
    onChange(key) {
      console.log(key, "change-----");
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.replace({ name: "freightTemplate" });
      }
      if (key === "2") {
        console.log(this.$refs.weightRef, "this.$refs.weightRef");

        if (this.$refs.weightRef) {
          this.$refs.weightRef.form = {
            name: "",
            initial_weight: 0,
            initial_amount: 0,
            subsequent_weight: 0,
            subsequent_amount: 0,
          };
          this.$refs.weightRef.freight_rule_items = []
          this.$refs.weightRef.freight_free_rule_items = []
        }
      }
    },
  },
};
</script>

<style scoped>
</style>